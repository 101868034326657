<template>
  <div>
    <b-row class="match-height">
      <b-col cols="12" xl="12">
        <b-card no-body class="remote-listagem">
          <b-card-body>
            <div class="listagem-top">
              <span class="text-listagem"> {{ $t("Device List") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col class="mb-1">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <b-input-group class="input-group-merge search-list ml-0">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        :placeholder="$t('Serial number or model')"
                      />
                    </b-input-group>
                    <b-button class="search-btn mr-0" variant="none">
                      <span>{{ $t("GO") }}</span>
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <vue-good-table
              :columns="columns"
              :rows="deviceRows"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: 0,
                perPageDropdown: 0,
                nextLabel: $t('Next'),
                prevLabel: $t('Prev'),
                rowsPerPageLabel: $t('Rows per page'),
                ofLabel: $t('of'),
              }"
              @on-row-click="openDeviceDetails"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'typology'" class="text-nowrap">
          <span class="text-nowrap">{{ $t(props.row.typology) }}</span>
        </span>

                <span
                  v-else-if="props.column.field === 'example2'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.example2 }}</span>
                </span>

                <span v-else-if="props.column.field === 'example3'">
                  <span class="text-nowrap">{{ props.row.example3 }}</span>
                </span>

                <span
                  v-else-if="props.column.field === 'example5'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.example5 }}</span>
                </span>

                <span
                  v-else-if="props.column.field === 'example6'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.example6 }}</span>
                </span>

                <span
                  v-else-if="props.column.field === 'status'"
                  class="text-nowrap"
                >
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>
              </template>

              <!-- pagination -->
              <!-- <template slot="pagination-bottom">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to 7 </span>
                    <span class="text-nowrap"> of 100 entries </span>
                  </div>
                  <div class="d-flex flex-wrap">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="pagination-btn mb-0"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template> -->
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { codeBasic } from "@/views/home/home-1/code";
import store from "@/store";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    VueGoodTable,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedDeviceType: null,
      selectedDevicesSearch: null,
      deviceTypeList: [
        { id: "Cabinet", label: this.$t("Cabinet") },
        { id: "Counter", label: this.$t("Counter") },
        { id: "Cold Room", label: this.$t("Cold Room") },
        { id: "Acura", label: this.$t("Acura") },
        { id: "Exige", label: this.$t("Exige") },
      ],

      perPage: 1,
      pageLength: 5,
      pageOptions: [5, 10, 20],
      dir: false,
      totalRows: 3,
      currentPage: 1,
      codeBasic,
      selected: { title: "Select..." },
      option: [
        { title: "Option One" },
        { title: "Option Two" },
        { title: "Option Three" },
        { title: "Option Four" },
      ],
      columns: [
        {
          label: this.$t("TYPE"),
          field: "typology",
        },
        {
          label: this.$t("SERIAL No."),
          width: 'auto',
          field: "serial_number",
        },
        {
          label: this.$t("MODEL"),
          field: "model_code",
        },
        {
          label: this.$t("DESCRIPTION"),
          field: "name",
        },
        {
          label: this.$t("SITE"),
          field: "location",
        },
        {
          label: this.$t("ACTIVATION"),
          field: "activation_date",
        },
        {
          label: this.$t("LAST UPDATE"),
          width: 'auto',
          field: "last_update",
          formatFn: (value) => {
            const dateTZ = new Date(`${value}Z`);
            const dayOfMonth =
              dateTZ.getDate() > 9 ? dateTZ.getDate() : `0${dateTZ.getDate()}`;
            const monthOfYear =
              dateTZ.getMonth() + 1 > 9
                ? dateTZ.getMonth() + 1
                : `0${dateTZ.getMonth() + 1}`;
            const year = dateTZ.getFullYear();
            const hourOfDay =
              dateTZ.getHours() > 9
                ? dateTZ.getHours()
                : `0${dateTZ.getHours()}`;
            const minuteOfHour =
              dateTZ.getMinutes() > 9
                ? dateTZ.getMinutes()
                : `0${dateTZ.getMinutes()}`;
            return `${year}-${monthOfYear}-${dayOfMonth} ${hourOfDay}:${minuteOfHour}`;
          },
        },
        {
          label: this.$t("STATUS"),
          field: "status",
        },
      ],
      rows: [
        {
          id: 1,
          type: "type",
          serial_number: "000",
          description: "text",
          activation_date: "02/07/20222",
          last_update: "11/07/2022",
          status: "OK!",
        },
      ],
      searchTerm: "",
      exemple7: [
        {
          1: "Ok!",
          2: "Alerta",
          3: "Oops",
        },
        {
          1: "light-activo",
          2: "light-offline",
          3: "light-alerta",
        },
      ],
    };
  },
  methods: {
    openDeviceDetails(props) {
      this.$router.push({
        name: "remote-details",
        // preserve current path and remove the first char to avoid the target URL starting with `//`
        params: { id: props.row.id },
      });
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        "OK!": "light-activo",
        Alerta: "light-offline",
        Oops: "light-alerta",
        /* eslint-enable key-spacing */
      };
      return (status) => statusColor[status];
    },
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    locationFilter() {
      return this.$store.state.location.locationFilter;
    },
    deviceRows() {
      // TODO: change to a different list
      let devicesWithNoRecipes = ["Blast Chiller"]
      return this.$store.state.devices.devicesList.filter((x) => {
        if (this.locationFilter) {
          return (
            !devicesWithNoRecipes.includes(x.typology) && x.location == this.locationFilter
          );
        } else return !devicesWithNoRecipes.includes(x.typology);
      });
    },
  },
  created() {
    this.$store.dispatch("devices/getRegisteredDevices", { context: this });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>