<template>
  <div>
    <b-row>
      <b-col v-for="location in deviceLocations" :key="location" cols="12" xl="3">
        <b-card no-body style="cursor: pointer" :class="locationFilter == location ? 'selected' : ''"
          @click="clickLocation(location)">
          <b-card-body class="remote-cards">
            <div class="remote-img">
              <img style="height: 250px" :src="locationImages(location)" alt="" />
              <span class="device-title">{{ location }}</span>
              <span class="number-devices">
                <feather-icon class="circle-icon green" icon="CircleIcon" />
                {{ locationCount(location) }} {{ locationCount(location) > 1 ? $t("Devices") : $t("Device") }}
                <feather-icon @click="openModalImagem(location)" style="cursor: pointer; margin-left: 10px"
                  icon="EditIcon" />
              </span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="locationModal" @ok="handleOkImage">
      <div class="image-gallery-container">
        <h2 class="heading-text">{{ $t("Upload image") }}</h2>
        <ul class="image-gallery">
          <li v-for="image in locationImagesList" :key="image.label" @click="handleImageSelect(image.label)">
            <img :src="image.url" :alt="image.label">
            <div :class="choosenImage === image.label ? 'overlay image-selected' : 'overlay'"></div>
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    vSelect,
  },
  FeatherIcon,
  directives: {
    Ripple,
  },
  data() {
    return {
      currentLocation: null,
      locationImagesList: [
        {
          label: "Blast Chiller and Freezer",
          url: require("@/assets/images/img/remote/blast-chille-and-freezer.jpg"),
        },
        {
          label: "Raspberries",
          url: require("@/assets/images/img/remote/close-up-raspberries-smoothie.jpg"),
        },
        {
          label: "Counter and Cabinet",
          url: require("@/assets/images/img/remote/Counter-and-cabinet.jpg"),
        },
        {
          label: "Fish Prep Counter",
          url: require("@/assets/images/img/remote/fish-prep-counter.jpg"),
        },
        {
          label: "Fresh Shrimp",
          url: require("@/assets/images/img/remote/Fresh-shrimp.jpg"),
        },
        {
          label: "Fresh Vegetable",
          url: require("@/assets/images/img/remote/fresh-vegetable.jpg"),
        },
        {
          label: "Frozen food in plate",
          url: require("@/assets/images/img/remote/Frozen-food-in-plate.jpg"),
        },
        {
          label: "Kitchen",
          url: require("@/assets/images/img/remote/Kitchen.jpg"),
        },
      ],
      choosenImage: null,
    };
  },
  methods: {
    clickLocation(location) {
      this.$store.commit("location/SET_LOCATION_FILTER", location);
    },
    locationCount(location) {
      return this.$store.state.devices.devicesList.filter(
        (dev) => dev.location == location && dev.typology != "Blast Chiller"
      ).length;
    },
    handleImageSelect(label) {
      this.choosenImage = label;
    },
    openModalImagem(location) {
      this.currentLocation = location;
      this.$bvModal.show("locationModal");
    },
    handleOkImage() {
      const locations_images = { ...this.locationsImagesList };

      locations_images[this.currentLocation] = this.choosenImage;

      this.$store.dispatch("users/updateLocations", { context: this, data: locations_images });

      this.choosenImage = null;
    },
    locationImages(location) {
      const locations_images = this.locationsImagesList;

      if (locations_images) {
        const storedImg = locations_images[location];
        const img = this.locationImagesList.find((img) => img.label === storedImg)

        if (img) {
          return img.url;
        }
      }

      return (
        "https://cooling.mercatus.pt/imagens/home/home_content_2.jpg"
      );
    },
  },
  computed: {
    locationFilter() {
      return this.$store.state.location.locationFilter;
    },
    showroomCountDevices() {
      return this.$store.state.devices.devicesList.filter(
        (dev) => dev.location == "Showroom"
      ).length;
    },
    testLabCountDevices() {
      return this.$store.state.devices.devicesList.filter(
        (dev) => dev.location == "Test Lab"
      ).length;
    },
    locationsImagesList() {
      if (this.$store.state.users.currentUser.location_image)
        return JSON.parse(this.$store.state.users.currentUser.location_image.replaceAll("'", '"'));

      return this.deviceLocations.reduce((acc, curr) => (acc[curr] = '', acc), {});
    },
    deviceLocations() {
      return [
        ...new Set(
          this.$store.state.devices.devicesList
            .filter((x) => x.typology != "Blast Chiller")
            .filter((x) => x.location)
            .map((device) => device.location)
            .sort((a, b) => a.localeCompare(b))
        ),
      ];
    },
  },
};
</script>
<style scoped>
.card {
  margin-bottom: 15px;
}

.selected {
  border: solid 1px #000;
}
</style>