var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12","xl":"12"}},[_c('b-card',{staticClass:"remote-listagem",attrs:{"no-body":""}},[_c('b-card-body',[_c('div',{staticClass:"listagem-top"},[_c('span',{staticClass:"text-listagem"},[_vm._v(" "+_vm._s(_vm.$t("Device List"))+" ")])])])],1),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"mb-1"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-input-group',{staticClass:"input-group-merge search-list ml-0"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),_c('b-form-input',{attrs:{"placeholder":_vm.$t('Serial number or model')}})],1),_c('b-button',{staticClass:"search-btn mr-0",attrs:{"variant":"none"}},[_c('span',[_vm._v(_vm._s(_vm.$t("GO")))])])],1)])],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.deviceRows,"select-options":{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"pagination-options":{
              enabled: true,
              perPage: 0,
              perPageDropdown: 0,
              nextLabel: _vm.$t('Next'),
              prevLabel: _vm.$t('Prev'),
              rowsPerPageLabel: _vm.$t('Rows per page'),
              ofLabel: _vm.$t('of'),
            }},on:{"on-row-click":_vm.openDeviceDetails},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'typology')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t(props.row.typology)))])]):(props.column.field === 'example2')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example2))])]):(props.column.field === 'example3')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example3))])]):(props.column.field === 'example5')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example5))])]):(props.column.field === 'example6')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example6))])]):(props.column.field === 'status')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status)}},[_vm._v(" "+_vm._s(props.row.status)+" ")])],1):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }